import { envType } from './ConfigConstant';
import DevConstant from './DevConstant.json';
import ProdConstant from './ProdConstant.json';
import StageConstant from './StageConstant.json';
import PreProdConstant from './PreProdConstant.json';


// Define configurations for all environments
const config: { [key: string]: any } = {
    dev: { ...DevConstant },
    stage: { ...StageConstant },
    prod: { ...ProdConstant },
    demo: { ...DevConstant }, // Demo uses the dev configuration by default
    preprod: { ...PreProdConstant },
};

// Export the configuration based on the current environment
// Defaults to `dev` if `REACT_APP_ENV` or `envType` is not set
export default config[process.env.REACT_APP_ENV || envType.dev];
